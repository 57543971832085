export const useApi = () => {
    const ip = process.env.REACT_APP_URL;

    const getApi = async (path, method, data) => {
        try {
            const response = await fetch(`${ip}/${path}`, {
                headers: {
                    'credentials': true,
                    'Content-Type': 'application/json',
                    'Accept-Encoding': 'gzip, deflate, br',
                    'Accept': '*/*',
                    'Connection': 'keep-alive',
                    'Access-Control': 'Allow-Origin'
                },
                origin: ip,
                credentials: 'include',
                method,
                body: JSON.stringify(data)
            });

            return response;
        } catch (error) {

        }
    };

    return { getApi };
};