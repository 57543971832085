import { AuthContext } from './context/authContext';

import { useAuth } from './hooks/useAuth';
import AdminPanel from './pages/AdminPanel';

import Auth from './pages/Auth';

import './styles.scss';
import 'normalize.css';

function App() {
    const auth = useAuth();

    return (
        <AuthContext.Provider value={auth}>
            {auth.isAuth
                ? <AdminPanel />
                : <Auth />
            }
        </AuthContext.Provider>
    );
}

export default App;