export const CardKey = ({ title, data, removeKey, toggleBan }) => {
    const remove = () => {
        removeKey(title);
    };

    const copyKey = async () => {
        try {
            if (navigator && navigator.clipboard && navigator.clipboard.writeText)
                return navigator.clipboard.writeText(title);
            return console.error('The Clipboard API is not available');
        } catch (error) {
            console.log(error.message);
        }
    };

    return (
        <div className={`card${data.isUsed || data.isBlocked ? ' used' : ''}`}>
            <div className='card__header'>
                <button onClick={copyKey}>Copy</button>
                <h4 className='card__key'>Key: {title}</h4>
            </div>
            <p>
                Mac: <b>{data.mac}</b>
            </p>
            <p>
                Last check: <b>{data.lastCheck}</b>
            </p>
            <p>
                Is banned: <b className={`${data.isBlocked ? 'red' : 'green'}`}>{data.isBlocked?.toString()}</b>
            </p>
            <p>
                isUsed: <b className={`${data.isUsed ? 'red' : 'green'}`}>{data.isUsed?.toString()}</b>
            </p>
            <div className='card__buttons'>
                <p>
                    Note: <b>{data.note}</b>
                </p>
                <button onClick={remove}>Remove</button>
                {data.isBlocked
                    ? <button value={title} onClick={toggleBan}>UnBan</button>
                    : <button name='ban' value={title} onClick={toggleBan}>Ban</button>}
            </div>
        </div>
    );
};