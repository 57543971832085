import { useEffect, useState } from 'react';
import { useApi } from '../../hooks/useApi';
import { CardKey } from './components/CardKey';

import './styles.scss';
import { type } from '@testing-library/user-event/dist/type';

const AdminPanel = () => {
    const [keys, setKeys] = useState();
    const [note, setNote] = useState('');
    const { getApi } = useApi();

    useEffect(() => {
        getKeys();

        setInterval(() => {
            getKeys();
        }, 1000 * 10);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getKeys = async () => {
        try {
            const response = await getApi('admin/keys', 'GET');

            const responseJson = await response?.json();

            if (!responseJson) {
                return;
            }

            setKeys(responseJson);
        } catch (error) {
            console.error(error);
        }
    };

    const generateKey = async () => {
        try {
            const response = await getApi('admin/generateKey', 'POST', { note });

            const responseJson = await response?.json();

            if (!responseJson) {
                return;
            }

            setKeys(responseJson);
            setNote('');
        } catch (error) {
            console.error(error);
        }
    };

    const removeKey = async (key) => {
        try {
            const response = await getApi(`admin/key?key=${key}`, 'DELETE');

            const responseJson = await response?.json();

            if (!responseJson) {
                return;
            }

            setKeys(responseJson);
        } catch (error) {
            console.error(error);
        }
    };

    const toggleBan = async (event) => {
        try {
            const { name, value } = event.currentTarget;

            const response = await getApi(`admin/banKey`, 'PUT', {
                key: value,
                isBlocked: name === 'ban' ? true : false
            });

            const responseJson = await response?.json();

            if (!responseJson) {
                return;
            }

            setKeys(responseJson);
        } catch (error) {
            console.error(error);
        }
    };

    const inputHandler = (e) => {
        setNote(e.target.value);
    };

    if (!keys) {
        return <h2>
            Loading...
        </h2>;
    }

    if (typeof keys === 'string') {
        <h2>
            Access Denied
        </h2>;
    }

    return (
        <main className='main'>
            <section className='main__section'>
                <h1 className='section__title'>
                    Admin panel
                </h1>

                {Object.keys(keys).map((key) =>
                    <CardKey
                        key={key}
                        title={key}
                        data={keys[key]}
                        removeKey={removeKey}
                        toggleBan={toggleBan}
                    />
                )}
                <div className='section__addBlock'>
                    <button onClick={generateKey}>Generate new key</button>
                    <input value={note} onChange={inputHandler} placeholder='Note' />
                </div>
            </section>
        </main>
    );
};

export default AdminPanel;