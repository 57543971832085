import { useContext, useEffect, useState } from "react";
import { useApi } from "../../hooks/useApi";
import { AuthContext } from "../../context/authContext";

import "./styles.scss";

const Auth = () => {
    const [password, setPassword] = useState("");
    const [status, setStatus] = useState("");

    const { setIsAuth } = useContext(AuthContext);

    const { getApi } = useApi();

    useEffect(() => {
        checkIsAuth();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault(e);

        setStatus("");

        const response = await getApi("auth", "POST", { password });

        setStatus(response?.status);

        if (!response?.ok) {
            return;
        }

        setIsAuth(true);
    };

    const checkIsAuth = async () => {
        const response = await getApi("auth", "GET");

        if (response?.status === 200) {
            setIsAuth(true);
        }
    };

    const handlePassword = (e) => {
        setPassword(e.currentTarget.value);
    };

    let message;

    if (status === 400) {
        message = "Password is incorrect. ";
    }

    return (
        <div className='auth'>
            <h1 className='title'>Auth</h1>
            <form className='auth__form' onSubmit={handleSubmit}>
                <input required className='input' type='password' onChange={handlePassword} placeholder='Password' />
                <button type='submit' className='button'>Log in</button>
            </form>
            <h4 className="error">{message}</h4>
        </div>
    );
};

export default Auth;